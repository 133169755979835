import React from 'react';
import type { UserData } from './AuthenticationService.type';

export type MFAStatus = 'none' | 'sent' | 'success';

export interface AuthenticationContextType {
	userData: UserData;
	mfaStatus: MFAStatus;
	setUser: (data?: UserData) => void;
	setMFAStatus: (status: MFAStatus) => void;
}

export default React.createContext<AuthenticationContextType>({
	userData: undefined,
	mfaStatus: 'none',
	setUser: (_data?: UserData) => {},
	setMFAStatus: (_status: MFAStatus) => {},
});
