import { Button, Container, Modal, Nav, Navbar, NavDropdown, NavItem, NavLink } from '@bankmonitor/bm-ui-kit';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autobind from 'autobind-decorator';
import dayjs from 'dayjs';
import type { ReactNode } from 'react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '@service/AuthenticationContext';
import AuthenticationService from '@service/AuthenticationService';
import { SESSION_TIMEOUT_MINUTES } from '@util/Config';

const SESSION_TIMEOUT = SESSION_TIMEOUT_MINUTES * 60;
const SESSION_TIMEOUT_WARNING = SESSION_TIMEOUT - 5 * 60;
const CHECK_INTERVAL = 1_000;

interface Props {}

interface State {
	timeOutSecs: number;
	showModal: boolean;
}

export default class Header extends React.PureComponent<Props, State> {
	public static contextType = AuthContext;
	// eslint-disable-next-line react/static-property-placement
	declare context: React.ContextType<typeof AuthContext>;
	private timerUpdateChecker: NodeJS.Timeout;

	public constructor(props: Props) {
		super(props);

		this.state = {
			timeOutSecs: AuthenticationService.getSessionTimeout(),
			showModal: false,
		};
	}

	public componentDidMount(): void {
		document.addEventListener('visibilitychange', this.handleVisibilityChange, false);

		this.handleVisibilityChange();
	}

	public componentWillUnmount(): void {
		document.removeEventListener('visibilitychange', this.handleVisibilityChange, false);
	}

	@autobind
	private handleVisibilityChange() {
		if (document.visibilityState === 'hidden') {
			clearInterval(this.timerUpdateChecker);
		} else {
			clearInterval(this.timerUpdateChecker);

			this.timerUpdateChecker = setInterval(() => {
				const { showModal } = this.state;
				const timeOutSecs = AuthenticationService.getSessionTimeout();

				this.setState({
					timeOutSecs,
				});

				if (!showModal && timeOutSecs >= SESSION_TIMEOUT_WARNING) {
					this.setState({
						showModal: true,
					});
				}
			}, CHECK_INTERVAL);
		}
	}

	@autobind
	private handleModalClose(): void {
		const { timeOutSecs } = this.state;

		const isExpired = timeOutSecs > SESSION_TIMEOUT;

		if (!isExpired) {
			AuthenticationService.getUserData().then(() => this.setState({ showModal: false }));
		} else {
			window.location.reload();
		}
	}

	@autobind
	private handleLogoOut(): void {
		AuthenticationService.logout().then(() => {
			const { setUser } = this.context;

			setUser(undefined);
			AuthenticationService.init(true);
		});
	}

	private renderModal(): ReactNode {
		const { showModal, timeOutSecs } = this.state;

		const isExpired = timeOutSecs > SESSION_TIMEOUT;

		if (isExpired) {
			return (
				<Modal show={showModal} onHide={this.handleModalClose}>
					<Modal.Header closeButton>
						<Modal.Title>Munkameneted lejárt!</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>
							A biztonság nagyon fontos, ezért vigyázunk rád. Mivel túl sokáig voltál tétlen az oldalon
							automatikusan kijelentkeztünk.
						</p>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={this.handleModalClose}>Bejelentkezés</Button>
					</Modal.Footer>
				</Modal>
			);
		}

		return (
			<Modal show={showModal} onHide={this.handleModalClose}>
				<Modal.Header closeButton>
					<Modal.Title>Munkameneted hamarosan lejár!</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						A biztonság nagyon fontos, ezért vigyázunk rád. Ha túl sokáig vagy tétlen az oldalon
						automatikusan kijelentkezünk a fiókodból, hogy nehogy illetéktelenek kezébe kerülhessenek az
						adataid!
					</p>
					<p>
						Ha szerenéd folytatni az ügyleted kezelését, kattints a folytatás gombra, amúgy a kijelentkezés
						gombra.
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="danger" outlined onClick={this.handleLogoOut}>
						Kijelentkezés
					</Button>
					<Button onClick={this.handleModalClose}>Folytatás</Button>
				</Modal.Footer>
			</Modal>
		);
	}

	public render(): ReactNode {
		const { userData, mfaStatus } = this.context;
		const { timeOutSecs } = this.state;

		if (!userData || mfaStatus !== 'success') {
			return null;
		}

		const timeOut = dayjs(`2000-01-01 00:${SESSION_TIMEOUT_MINUTES}:00`)
			.subtract(timeOutSecs, 'seconds')
			.format('mm:ss');

		const tenant = window.TENANT;

		return (
			<>
				<header className="main-header">
					<Navbar bg="light" expand="lg" className="px-2">
						<Container>
							<Navbar.Brand as={Link} to="/" className="me-3">
								<img
									width={tenant.defaultImg.width || 160}
									height={tenant.defaultImg.height || 23}
									alt={tenant.defaultImg.alt}
									src={tenant.defaultImg.src}
								/>
							</Navbar.Brand>
							<Navbar.Toggle aria-controls="basic-navbar-nav" />
							<Navbar.Collapse id="basic-navbar-nav" className="w-100">
								<Nav className="me-auto">
									<NavItem>
										<NavLink as={Link} to="/">
											<FontAwesomeIcon
												icon={solid('list-check')}
												className="me-2 text-secondary"
											/>
											Ügyleteim
										</NavLink>
									</NavItem>
								</Nav>
								<NavDropdown title={`${userData.lastName} ${userData.firstName}`} id="profile-dropdown">
									<NavDropdown.Item onClick={this.handleLogoOut}>
										<>Kijelentkezés ({timeOut})</>
									</NavDropdown.Item>
								</NavDropdown>
							</Navbar.Collapse>
						</Container>
					</Navbar>
				</header>
				{this.renderModal()}
			</>
		);
	}
}
