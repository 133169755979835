import type { FormSelectOptions } from '@bankmonitor/bm-ui-kit';
import { Http } from '@bankmonitor/bm-ui-kit';
import { BM_API_URL } from '@util/Config';
import type { BankBranch, ConsultantProfileData, FeedBackRequest } from './CommonService.type';

export default class CommonService {
	/**
	 * @url https://bankmonitor.atlassian.net/wiki/spaces/UP/pages/2241036336/Bankfi+kok+lek+rdez+se
	 */
	public static getBankBranches(bankCode: string): Promise<BankBranch[]> {
		return Http.get(`/api/public/customer-portal/common/bank/${bankCode}/branch`);
	}

	/**
	 * @url https://bankmonitor.atlassian.net/wiki/spaces/UP/pages/2241101880/V+laszthat+hitelt+pusok
	 */
	public static getBankLoanTypes(bankCode: string, productType: string): Promise<FormSelectOptions> {
		return Http.get(`/api/public/customer-portal/common/bank/${bankCode}/loan-types/${productType}`);
	}

	/**
	 * @url https://bankmonitor.atlassian.net/wiki/spaces/UP/pages/2241036343/V+laszthat+j+vedelem+t+pusok
	 */
	public static getBankIncomeTypes(bankCode: string, productType: string): Promise<FormSelectOptions> {
		return Http.get(`/api/public/customer-portal/common/bank/${bankCode}/income-types/${productType}`);
	}

	public static getMinWage(): Promise<number> {
		return Http.get(`${BM_API_URL}/api/public/general-calculation-values/min-wage`).then(
			(result) => result.netValue,
		);
	}

	/**
	 * @url https://bankmonitor.atlassian.net/wiki/spaces/UP/pages/2262433797/gyint+z+adat+lek+rdez+se
	 */
	public static getConsultantData(consultantId: string): Promise<ConsultantProfileData> {
		return Http.get(`/api/public/customer-portal/consultant/${consultantId}`);
	}

	/**
	 * @url https://bankmonitor.atlassian.net/wiki/spaces/UP/pages/2265120777/Feedback+k+ld+se
	 */
	public static sendFeedBack(data: FeedBackRequest): Promise<void> {
		return Http.post(`/api/public/customer-portal/feedback`, data);
	}
}
