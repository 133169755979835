export const SESSION_TIMEOUT_MINUTES = 30;
export const {
	VITE_AGGREG8_SYNC_UI_URL: AGGREG8_SYNC_UI_URL,
	VITE_BM_API_URL: BM_API_URL,
	VITE_BM_SITE_URL: BM_SITE_URL,
	VITE_BM_STATIC_DOCS_URL: BM_STATIC_DOCS_URL,
	VITE_ENVIRONMENT: BM_ENVIRONMENT,
	VITE_FACEKOM_API_URL: FACEKOM_API_URL,
	VITE_GA_MEASUREMENT_ID: GA_MEASUREMENT_ID,
	VITE_SENTRY_DSN: SENTRY_DSN,
	VITE_VERSION: BM_VERSION = 'dev',
} = import.meta.env;
export const BM_BASE_URL = '';
export const MAINTENANCE_ENABLED = import.meta.env.VITE_MAINTENANCE_ENABLED === 'true';

export function getBaseUrl(): string {
	return window.location.origin;
}
