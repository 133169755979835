import type { HttpError } from '@bankmonitor/bm-ui-kit';
import type { ReactNode } from 'react';
import { logError } from './sentry-error';

interface ReadableErrorSettings {
	error?: Error | HttpError;
	render?: ReactNode;
	needLog?: boolean;
}

export class ReadableError extends Error {
	public readonly name = 'ReadableError';
	public readonly displayError: ReactNode;
	public readonly error?: Error;
	public readonly errorCode?: string;
	public readonly needLog?: boolean;

	public constructor(message: string, data?: ReadableErrorSettings) {
		super(message);

		this.error = data?.error;
		this.displayError = data?.render || message;
		this.needLog = data?.needLog;
		this.errorCode = data?.error && ('errorCode' in data.error ? data.error.errorCode : undefined);

		if (this.needLog === true) {
			logError(this);
		}
	}

	public static getReadableError(error: Error | HttpError | ReadableError): ReadableError {
		if ('displayError' in error) {
			return error;
		}

		if ('errorCode' in error) {
			return new ReadableError(error.message || error.errorCode, {
				error,
				render: error.message,
				needLog: false, // error.needLog,
			});
		}

		return new ReadableError(error.message, { error, render: error.message });
	}
}
