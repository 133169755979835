import type { HttpError, ProgressButtonState } from '@bankmonitor/bm-ui-kit';
import {
	ProgressButton,
	Spinner,
	FormSubmitButton,
	Display,
	Form,
	Button,
	PromisePool,
	FormTextInput,
} from '@bankmonitor/bm-ui-kit';
import { duotone, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autobind from 'autobind-decorator';
import * as React from 'react';
import type { ReactNode } from 'react';
import Notifications from '@component/Notifications';
import AuthContext from '@service/AuthenticationContext';
import AuthenticationService from '@service/AuthenticationService';
import type { ReadableError } from '@util/ReadableError';

interface Props {
	phone: string;
}

interface State {
	status: ProgressButtonState;
	page: 'init' | 'password' | 'help';
}

interface FormFields {
	code: string;
}

export default class MFAComponent extends React.Component<Props, State> {
	public static contextType = AuthContext;
	// eslint-disable-next-line react/static-property-placement
	declare context: React.ContextType<typeof AuthContext>;
	protected readonly promisePool = new PromisePool();

	public constructor(props: Props) {
		super(props);
		this.state = {
			status: undefined,
			page: 'init',
		};
	}

	public componentDidMount(): void {
		const { mfaStatus } = this.context || { mfaStatus: 'none' };

		this.setState({
			page: mfaStatus === 'sent' ? 'password' : 'init',
		});
	}

	public componentWillUnmount(): void {
		this.promisePool.cancel();
	}

	@autobind
	private handleSendSMSClick(): void {
		this.setState({
			status: 'loading',
		});

		this.promisePool
			.add(AuthenticationService.sendMFA())
			.then(() => {
				this.navigateToPasswordPage();
				this.setState({
					status: undefined,
				});

				Notifications.pushSuccessMessage('Az SMS kiküldve!');
			})
			.catch((error: HttpError | ReadableError) => {
				if (error.errorCode === 'SMS_SENT') {
					Notifications.pushInfoMessage(error.message);
					this.setState({
						status: undefined,
					});

					this.navigateToPasswordPage();
				} else {
					this.setState({
						status: 'error',
					});

					Notifications.showErrorMessage(error, '');
				}
			})
			.finally(() => {
				this.promisePool.addTimeout().then(() => {
					this.setState({
						status: undefined,
					});
				});
			});
	}

	@autobind
	private handleSubmit(values: FormFields): void {
		const { setMFAStatus } = this.context;

		this.promisePool
			.add(AuthenticationService.validateMFA(values.code))
			.then(() => {
				this.setState({
					status: 'done',
				});

				setMFAStatus('success');
			})
			.catch((error: HttpError) => {
				Notifications.showErrorMessage(error);

				this.setState({
					status: 'error',
				});
			})
			.finally(() => {
				this.promisePool.addTimeout().then(() => {
					this.setState({
						status: undefined,
					});
				});
			});
	}

	@autobind
	private handleHelpClick(): void {
		this.setState({
			page: 'help',
		});
	}

	@autobind
	private handleBackClick(): void {
		this.setState({
			page: 'password',
		});
	}

	private validCode(code: string): string {
		const codeNumber = code.replace(/ /g, '');

		if (codeNumber.length < 6) {
			return 'A megadott ellenőrző kód túl rövid!';
		}

		if (parseInt(codeNumber, 10) > 999_999) {
			return 'A megadott ellenőrző kód túl hosszú!';
		}
	}

	private navigateToPasswordPage(): void {
		const { setMFAStatus } = this.context;

		this.setState({
			page: 'password',
			status: undefined,
		});

		setMFAStatus('sent');
	}

	private initialValues(): FormFields {
		return {
			code: '',
		};
	}

	private rendeContent(): ReactNode {
		const { status } = this.state;

		switch (status) {
			case 'loading':
				return (
					<div className="text-center">
						<Spinner variant="info" size="2x" className="my-4" />
					</div>
				);
			case 'done':
				return (
					<div className="text-center">
						<FontAwesomeIcon icon={duotone('check-circle')} beatFade />
					</div>
				);
			default:
		}
	}

	private renderInitPageContent(): ReactNode {
		const { phone = '' } = this.props;
		const { status } = this.state;

		return (
			<>
				<p className="mb-3 ps-2">A hitelesítéshez szükséges kódot az alábbi telefonszámra fogjuk kiküldeni:</p>
				<FontAwesomeIcon icon={solid('mobile-screen')} className="me-2" /> <strong>{phone}</strong>
				<ProgressButton
					className="w-100 mt-3 mb-2"
					icon={<FontAwesomeIcon icon={solid('comment-sms')} />}
					onClick={this.handleSendSMSClick}
					state={status}
				>
					SMS küldése
				</ProgressButton>
			</>
		);
	}

	private renderPasswordPageContent(): ReactNode {
		const { phone = '' } = this.props;
		const { status } = this.state;

		return (
			<Form initialValues={this.initialValues()} onSubmit={this.handleSubmit}>
				{() => (
					<>
						<p className="mb-3">A kódot a következő telefonszámra küldtük:</p>
						<FontAwesomeIcon icon={solid('mobile-screen')} className="me-1" /> <strong>{phone}</strong>
						<FormTextInput
							className="w-50 mt-3 mb-0"
							label="Ellenőrző kód:"
							name="code"
							prepend={<FontAwesomeIcon icon={solid('comment-sms')} />}
							validate={this.validCode}
							autoComplete="off"
							maxLength={6}
							required
						/>
						<small className="text-info">
							<FontAwesomeIcon icon={solid('circle-question')} /> Kérjük adja meg az SMS azonosító kódját.
						</small>
						<div className="mt-3">
							<FormSubmitButton
								icon={<FontAwesomeIcon icon={solid('arrow-right')} />}
								state={status}
								className="mb-2 me-3"
							>
								Tovább
							</FormSubmitButton>
							<Button
								variant="dark"
								outlined
								icon={<FontAwesomeIcon icon={solid('life-ring')} />}
								onClick={this.handleHelpClick}
								className="mb-2"
							>
								Nem kaptam meg az SMS-t
							</Button>
						</div>
					</>
				)}
			</Form>
		);
	}

	private renderHelpPageContent(): ReactNode {
		const contactEmail = window.TENANT.contact.email;
		const emailUrl = `mailto:${contactEmail}?subject=${encodeURIComponent('Téves telefonszám')}`;

		return (
			<>
				<strong>2 faktoros hitelesítés - SMS ellenőrzés</strong>

				<p className="mb-3">
					Ha nem kaptad meg az SMS-t valamilyen okból, kérjük vedd fel velünk a kapcsolatot az alábbi címen:
				</p>
				<a href={emailUrl} className="text-body">
					<FontAwesomeIcon icon={solid('envelope')} /> {contactEmail}
				</a>
				<br />
				<Button className="me-auto mt-3" outlined onClick={this.handleBackClick}>
					Vissza
				</Button>
			</>
		);
	}

	public render(): ReactNode {
		const { status, page } = this.state;

		return (
			<>
				<Display size="4" className="fw-bold mb-3">
					SMS ellenőrzés
				</Display>
				{this.rendeContent()}
				{[undefined, 'error'].includes(status) && (
					<>
						{page === 'init' && this.renderInitPageContent()}
						{page === 'password' && this.renderPasswordPageContent()}
						{page === 'help' && this.renderHelpPageContent()}
					</>
				)}
			</>
		);
	}
}
