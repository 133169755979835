import { Container, Spinner } from '@bankmonitor/bm-ui-kit';
import type { ReactNode } from 'react';
import React from 'react';

class LoadingPage extends React.PureComponent {
	public render(): ReactNode {
		return (
			<div className="d-flex align-items-center full-height">
				<Container fluid className="text-center">
					<Spinner size="3x" variant="primary" />
				</Container>
			</div>
		);
	}
}

export default LoadingPage;
