import type { HttpError } from '@bankmonitor/bm-ui-kit';
import { Button, Http, Cookie } from '@bankmonitor/bm-ui-kit';
import React from 'react';
import { Analytics } from '@util/Analytics';
import { BM_ENVIRONMENT, SESSION_TIMEOUT_MINUTES } from '@util/Config';
import { ReadableError } from '@util/ReadableError';
import type { UserData } from './AuthenticationService.type';

const MFA_TOKEN_COOKIE = 'bm-mfa-token';
const LAST_SESSION_INTERACTION = 'bm-last-session-interaction';

export default class AuthenticationService {
	public static init(refresh?: boolean): Promise<void> {
		return new Promise((resolve, reject) => {
			Http.setDefaultHeader({
				'x-csrf-token': undefined,
			});

			let name = document.querySelector('meta[name="_csrf_header"]')?.getAttribute('content');
			let value = document.querySelector('meta[name="_csrf"]')?.getAttribute('content');

			if (name && value && !refresh) {
				resolve({ name, value });
			} else {
				const tokenUrl = BM_ENVIRONMENT === 'dev' ? '/index' : '/';
				Http.get(tokenUrl)
					.then((content: string) => {
						name = content.match(/<meta name="_csrf_header" content="([^"]+)"/)?.[1];
						value = content.match(/<meta name="_csrf" content="([^"]+)"/)?.[1];

						resolve({ name, value });
					})
					.catch(reject);
			}
		}).then(({ name, value }) => {
			Http.setDefaultHeader({
				[name]: value,
			});
		});
	}

	public static login(email: string, password: string): Promise<void> {
		AuthenticationService.resetMFA();

		return Http.post(`/api/public/customer-portal/login`, {
			email,
			password,
		}).catch((error: HttpError) => {
			switch (error.errorCode) {
				case 'FORBIDDEN':
					throw new ReadableError('Hozzáférés megtagadva!', { error, needLog: false });
				case 'WRONG_EMAIL_OR_PASSWORD':
					throw new ReadableError('Érvénytelen email vagy jelszó!', {
						error,
						render: (
							<>
								Érvénytelen email vagy jelszó!
								<br />
								<br />
								<Button
									outlined
									size="sm"
									href={AuthenticationService.getRedirectWithParamsUrl('/elfelejtett-jelszo/')}
								>
									Elfelejtett jelszó?
								</Button>
							</>
						),
						needLog: false,
					});
				case 'UNCONFIRMED_EMAIL':
					throw new ReadableError('Megerősítetlen email cím!', { error, needLog: false });
				case 'TOO_MANY_ATTEMPTS':
					throw new ReadableError(
						'Túl sokat próbálkozott (5x sikertelen próbálkozás 1 órán belül - 1 óráig szüneteltetjük a bejelentkeztetést)',
						{ error, needLog: false },
					);
				default:
					throw error;
			}
		});
	}

	public static registration(email: string, password: string, emailToken?: string): Promise<boolean> {
		AuthenticationService.resetMFA();

		return Http.post(`/api/public/customer-portal/registration`, {
			email,
			emailToken,
			password,
		})
			.then(() => {
				Analytics.event({
					category: 'egyeb',
					label: 'login',
					action: 'registration',
				});

				return true;
			})
			.catch((error: HttpError) => {
				switch (error.errorCode) {
					case 'INVALID_PASSWORD':
						throw new ReadableError(
							'Érvénytelen jelszó (legalább 8 karakter, minimum egy szám és egy betű karaktert kell hogy tartalmazzon)',
							{ error, needLog: false },
						);
					case 'WRONG_EMAIL_OR_TOKEN':
						throw new ReadableError('Érvénytelen email cím/token páros.', { error, needLog: false });
					case 'EXISTING_EMAIL':
						setTimeout(() => {
							window.location.href = this.getRedirectWithParamsUrl('/');
						}, 1500);

						throw new ReadableError('Foglalt email cím. Kérlek jelentkezz be!', {
							error,
							needLog: false,
						});
					default:
						throw new ReadableError('Hiba a regisztráció közben!', {
							error,
							needLog: true,
						});
				}
			});
	}

	public static logout(): Promise<void> {
		return Http.post(`/api/public/customer-portal/logout`, {}).then(() => {
			AuthenticationService.resetMFA();
		});
	}

	public static getRedirectWithParamsUrl(toUrl?: string): string {
		const url = toUrl || window.location.href;
		const searchParams = new URLSearchParams(window.location.search.replace('?', ''));
		const redirectTo = searchParams.get('redirect_to');
		searchParams.delete('token');
		const currentDomain = `${window.location.protocol}//${window.location.host}`;
		let redirectUrl: string;

		if (redirectTo) {
			if (redirectTo.startsWith('http')) {
				if (redirectTo.startsWith(currentDomain)) {
					redirectUrl = redirectTo;
				}
			} else {
				redirectUrl = `/${redirectTo.replace(/\/{2,}/g, '/')}`;
			}
		}

		if (redirectUrl) {
			searchParams.set('redirect_to', redirectUrl);
		}

		const paramsString = searchParams.toString();

		return paramsString ? `${url}?${paramsString}` : url;
	}

	public static lostPassword(email: string): Promise<void> {
		return Http.post(`/api/public/customer-portal/lost-password`, {
			email,
		}).then(() => {
			Analytics.event({
				category: 'egyeb',
				label: 'login',
				action: 'lost-password',
			});
		});
	}

	public static changePassword(oldPassword: string, newPassword: string): Promise<void> {
		return Http.post(`/api/public/customer-portal/change-password`, {
			oldPassword,
			newPassword,
		});
	}

	public static modifyLostPassword(email: string, emailToken: string, password: string): Promise<void> {
		return Http.put(`/api/public/customer-portal/lost-password`, {
			email,
			emailToken,
			password,
		});
	}

	public static validateEmailToken(
		email: string,
		emailToken: string,
	): Promise<{ lastName: string; firstName: string }> {
		return Http.post(`/api/public/customer-portal/validate-email-token`, {
			email,
			emailToken,
		})
			.then((response) => {
				if (!response?.lastName) {
					throw new ReadableError('Váratlan hiba történt!');
				}

				return response;
			})
			.catch((error: HttpError) => {
				switch (error.errorCode) {
					case 'WRONG_EMAIL_OR_TOKEN':
						throw new ReadableError('Érvénytelen email cím/token páros!', { error, needLog: false });
					case 'FIELD_ERROR':
						throw new ReadableError('Hiányos email cím vagy token!', { error, needLog: false });
					default:
						throw error;
				}
			});
	}

	public static sendMFA(): Promise<string> {
		AuthenticationService.resetMFA();

		return Http.post(`/api/public/customer-portal/mfa/send`)
			.then((response) => {
				if (!response.phone) {
					throw new ReadableError('Váratlan hiba történt!');
				}

				return response.phone;
			})
			.catch((error: HttpError) => {
				if (error.status === 403) {
					throw new ReadableError('Hozzáférés megtagadva!', { error, needLog: false });
				}

				switch (error.errorCode) {
					case 'WRONG_SMS_CODE':
						throw new ReadableError('Hibás ellenőrző kód!', { error, needLog: false });
					case 'SMS_SENT':
						throw new ReadableError('Az SMS-t korábban már elküldtük!', { error, needLog: false });
					case 'MISSING_PHONE':
						throw new ReadableError('Nincs megadva telefonszám!', { error, needLog: true });
					case 'TOO_MANY_ATTEMPTS':
						throw new ReadableError('Túl sokat próbálkoztál (5x sikertelen próbálkozás 1 órán belül)!', {
							error,
							needLog: false,
						});
					default:
						throw error;
				}
			});
	}

	public static updateSession(): void {
		Cookie.set(LAST_SESSION_INTERACTION, new Date().getTime().toString(), SESSION_TIMEOUT_MINUTES);
		Cookie.set(MFA_TOKEN_COOKIE, Cookie.get(MFA_TOKEN_COOKIE), SESSION_TIMEOUT_MINUTES);
	}

	public static getSessionTimeout(): number {
		return Math.max(0, new Date().getTime() - Number(Cookie.get(LAST_SESSION_INTERACTION))) / 1000;
	}

	public static hasMFA(): boolean {
		const mfa = Cookie.get(MFA_TOKEN_COOKIE);

		Http.setDefaultHeader({
			bm_mfa_token: mfa || null,
		});

		return !!mfa;
	}

	public static resetMFA(): void {
		Cookie.set(MFA_TOKEN_COOKIE, undefined);

		Http.setDefaultHeader({
			bm_mfa_token: null,
		});
	}

	public static validateMFA(code: string): Promise<string> {
		return Http.post(`/api/public/customer-portal/mfa/validate`, {
			code,
		})
			.then((response) => {
				if (!response.mfaToken) {
					throw new ReadableError('Hozzáférés megtagadva!', { needLog: false });
				}

				Http.setDefaultHeader({
					bm_mfa_token: response.mfaToken,
				});
				Cookie.set(MFA_TOKEN_COOKIE, response.mfaToken, SESSION_TIMEOUT_MINUTES);

				return response.mfaToken;
			})
			.catch((error: HttpError) => {
				switch (error.errorCode) {
					case 'WRONG_SMS_CODE':
						throw new ReadableError('Hibás ellenőrző kód!', { error, needLog: false });
					case 'TOO_MANY_ATTEMPTS':
						throw new ReadableError('Túl sokat próbálkoztá (5x sikertelen próbálkozás 1 órán belül)!', {
							error,
							needLog: false,
						});
					default:
						throw error;
				}
			});
	}

	public static getUserData(): Promise<UserData> {
		return Http.get(`/api/public/customer-portal/customer`)
			.then((response) => {
				if (!response.phone) {
					throw new ReadableError('Belső hiba!');
				}

				return response;
			})
			.catch((error: HttpError) => {
				switch (error.errorCode) {
					case 'CUSTOMER_NOT_FOUND':
						throw new ReadableError('A felhasználó nem található!', { error, needLog: true });
					default:
						throw error;
				}
			});
	}
}
