import { Toast, ToastContainer } from '@bankmonitor/bm-ui-kit';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import React from 'react';
import { Analytics } from '@util/Analytics';
import { ReadableError } from '@util/ReadableError';

const DEFAULT_AUTO_CLOSE = 5_000;
const FADE_OUT = 2_000;

interface ToastSettings {
	title: string;
	icon: ReactNode;
	message: ReactNode;
	variant: string;
	autoClose?: number | 'never';
	onClose?: () => void;
}

interface ToastItemInstance extends ToastSettings {
	id?: number;
	timeOut?: NodeJS.Timeout;
}

interface Props {}

interface State {
	toasts: ToastItemInstance[];
	hiddenToast: number[];
}

export default class Notifications extends React.Component<Props, State> {
	private static readonly staticGroup: Record<number, (toast: State, callback?: () => void) => void> = {};
	private static staticState: State = { toasts: [], hiddenToast: [] };
	private static counter = 0;
	private static toastCounter = 0;

	public static pushCommingSoonMessage(action: string): number {
		Analytics.event({
			category: 'other',
			action,
			label: 'comming-soon',
		});

		return Notifications.addToast({
			variant: 'info',
			message: 'Ez a funkció jelenleg még nem érhető el!',
			title: 'Fejlesztés alatt!',
			icon: <FontAwesomeIcon icon={duotone('triangle-person-digging')} />,
		});
	}

	public static pushDangerMessage(message: ReactNode, title?: string, icon?: ReactNode): number {
		return Notifications.addToast({
			variant: 'danger',
			message,
			title: title || 'Hiba!',
			icon: icon || <FontAwesomeIcon icon={duotone('circle-x')} />,
		});
	}

	public static pushSuccessMessage(message: ReactNode, title?: string, icon?: ReactNode): number {
		return Notifications.addToast({
			variant: 'success',
			message,
			title: title || 'Kész!',
			icon: icon || <FontAwesomeIcon icon={duotone('circle-check')} />,
		});
	}

	public static pushInfoMessage(message: ReactNode, title?: string, icon?: ReactNode): number {
		return Notifications.addToast({
			variant: 'info',
			message,
			title: title || 'Infó!',
			icon: icon || <FontAwesomeIcon icon={duotone('circle-info')} />,
		});
	}

	public static showErrorMessage(error: Error, defaultMessage: string = 'Váratlan hiba történt!'): number {
		const e = ReadableError.getReadableError(error);

		return Notifications.pushDangerMessage(e.displayError || e.message || defaultMessage);
	}

	private static addToast(toast: ToastSettings): number {
		const { toasts } = Notifications.staticState;

		const id = Notifications.toastCounter;
		Notifications.toastCounter += 1;

		let timeOut;

		if (toast.autoClose !== 'never') {
			timeOut = setTimeout(() => {
				Notifications.remove(id);
			}, toast.autoClose || DEFAULT_AUTO_CLOSE);
		}

		Notifications.staticState = {
			...Notifications.staticState,
			toasts: [...toasts, { ...toast, id, timeOut }],
		};
		Object.values(Notifications.staticGroup).forEach((setState) => {
			if (setState) {
				setState(Notifications.staticState);
			}
		});

		return id;
	}

	public static remove(toastId: number): void {
		Notifications.addHiddenToast(toastId);

		setTimeout(() => {
			const { toasts } = Notifications.staticState;

			const newToasts = toasts.filter((toast) => {
				if (toast.id === toastId) {
					const callback = toast.onClose;

					if (callback) {
						callback();
					}

					return false;
				}

				return true;
			});

			Notifications.staticState = { ...Notifications.staticState, toasts: newToasts };

			Object.values(Notifications.staticGroup).forEach((setState) => {
				if (setState) {
					setState(Notifications.staticState);
				}
			});
		}, FADE_OUT);
	}

	public static addHiddenToast(id: number): void {
		const { hiddenToast } = Notifications.staticState;

		const newHiddenToast = [...hiddenToast, id];

		Notifications.staticState = { ...Notifications.staticState, hiddenToast: newHiddenToast };

		Object.values(Notifications.staticGroup).forEach((setState) => {
			if (setState) {
				setState(Notifications.staticState);
			}
		});
	}

	private readonly id: number;

	public constructor(props: Props) {
		super(props);

		this.state = Notifications.staticState;
		this.id = Notifications.counter;
		Notifications.counter += 1;
	}

	public componentDidMount(): void {
		Notifications.staticGroup[this.id] = (s: State, c?: () => void) => this.setState(s, c);
	}

	public componentWillUnmount(): void {
		Notifications.staticGroup[this.id] = undefined;
	}

	public render(): ReactNode {
		const { toasts, hiddenToast } = this.state;

		if (!toasts) {
			return null;
		}

		return (
			<ToastContainer className="position-fixed p-3 pt-5 pt-md-3 mt-5 mt-md-0" position="bottom-end">
				{toasts.map((toast) => (
					<Toast
						key={toast.id}
						show={!hiddenToast.includes(toast.id)}
						onClose={() => Notifications.remove(toast.id)}
						onMouseEnter={() => {
							if (toast.timeOut) {
								clearTimeout(toast.timeOut);
							}
						}}
						onMouseLeave={() => {
							if (toast.autoClose !== 'never') {
								// eslint-disable-next-line no-param-reassign
								toast.timeOut = setTimeout(() => {
									Notifications.remove(toast.id);
								}, toast.autoClose || DEFAULT_AUTO_CLOSE);
							}
						}}
					>
						<Toast.Body
							className={`border border-5 border-end-0 border-top-0 border-bottom-0 border-${toast.variant}`}
						>
							<div className="d-flex flex-nowrap align-items-center">
								<div className={`fs-1 pe-3 me-2 text-${toast.variant}`}>{toast.icon}</div>
								<div>
									<div className={`h4 mb-1 text-${toast.variant}`}>{toast.title}</div>
									<div>{toast.message}</div>
								</div>
							</div>
						</Toast.Body>
					</Toast>
				))}
			</ToastContainer>
		);
	}
}
