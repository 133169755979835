import { Tenant, TENANT_CONFIG } from './tenant.config';

export function isTenant(tenant: string): asserts tenant is Tenant {
	if (!Object.keys(Tenant).includes(tenant)) {
		throw new Error('Provided value is not a valid tenant');
	}
}

export const setTenantTheme = async (tenant: Tenant) => {
	switch (tenant) {
		case 'BM':
			await import('@style/theme/bm_theme.scss');
			break;
		case 'OVB':
			await import('@style/theme/ovb_theme.scss');
			break;
		case 'DH':
			await import('@style/theme/dh_theme.scss');
			break;
		case 'PLDPZGY':
			await import('@style/theme/pld_pzgy_theme.scss');
			break;
		default:
			console.warn('No tenant skin found');
			break;
	}
};

const addLink = (rel: string, href: string) => {
	const link = document.createElement('link');
	link.rel = rel;
	link.href = href;
	document.head.appendChild(link);
};

export const initTenant = async () => {
	const tenant = (document.querySelector('meta[name="tenant"]') as HTMLMetaElement)?.content || 'BM';
	isTenant(tenant);
	const tenantConfig = TENANT_CONFIG[tenant];
	window.TENANT = tenantConfig;
	document.title = `Ügyfélportál | ${tenantConfig.name}`;
	await setTenantTheme(tenant);
	addLink('icon', tenantConfig.miniLogo?.src ?? tenantConfig.defaultImg.src);

	if (tenantConfig.code === 'BM') {
		addLink('manifest', '/manifest.json');
	}
};
