import type { HttpError, ProgressButtonState } from '@bankmonitor/bm-ui-kit';
import { getUrlParameter } from '@bankmonitor/bm-ui-kit';
import autobind from 'autobind-decorator';
import React from 'react';
import type { ReactNode } from 'react';
import { Link, Navigate } from 'react-router-dom';
import imgLostPassword from '@asset/img/lost-password.svg';
import Notifications from '@component/Notifications';
import LostPasswordForm from '@page/auth/component/LostPasswordForm';
import type { FormFields as LostPasswordFormFields } from '@page/auth/component/LostPasswordForm';
import type { FormFields as ResetPasswordFormFields } from '@page/auth/component/ResetPasswordForm';
import ResetPasswordForm from '@page/auth/component/ResetPasswordForm';
import AuthenticationService from '@service/AuthenticationService';
import type { ConsultantProfileData } from '@service/CommonService.type';
import { logError } from '@util/sentry-error';
import type { AbstractPageProps } from '../AbstractPage';
import { AbstractAuthPage } from './AbstractAuthPage';
import type { AbstractAuthPageState } from './AbstractAuthPage';

interface State extends AbstractAuthPageState {
	error?: string;
	status: ProgressButtonState;
	page: 'lost-password' | 'reset-password';
	email: string;
	token: string;
	isKeyValid: boolean;
	consultantData: ConsultantProfileData;
}

export default class LostPasswordPage extends AbstractAuthPage<AbstractPageProps, State> {
	protected readonly pageTitle = 'Ügyfélportál bejelentkezés';
	protected readonly pageLead = 'Gyorsítsd fel az ügyintézés folyamatát!';

	public getInitialState(): State {
		return {
			...super.getInitialState(),
			status: undefined,
			email: getUrlParameter('email'),
			token: getUrlParameter('token'),
			page: 'lost-password',
			isKeyValid: false,
		};
	}

	public componentDidMount(): void {
		const isResetPasswordPage = this.isResetPasswordPage();
		this.setState(
			{
				page: isResetPasswordPage ? 'reset-password' : 'lost-password',
			},
			() => {
				if (isResetPasswordPage) {
					this.validateEmailToken();
				}
			},
		);
	}

	private validateEmailToken(): void {
		const { email, token } = this.state;
		AuthenticationService.validateEmailToken(email, token)
			.then(() => {
				this.setState({
					isKeyValid: true,
				});
			})
			.catch((response: Error) => {
				this.setState({
					isKeyValid: false,
					error: response.message || 'Érvénytelen adatok!',
				});

				logError(response);
			});
	}

	private isResetPasswordPage(): boolean {
		const { email, token } = this.state;

		return email !== undefined && token !== undefined;
	}

	@autobind
	private handleSubmitLostPassword(values: LostPasswordFormFields): void {
		this.setState({
			status: 'loading',
		});
		AuthenticationService.lostPassword(values.email)
			.then(() => {
				this.setState({
					status: 'done',
				});

				Notifications.pushSuccessMessage(
					'A megadott email címre elküldtünk egy linket. Kérjük kattints a linkre és állítsd be az új jelszavadat.',
				);
			})
			.catch(() => {
				this.setState({
					error: 'Belső hiba történt!',
					status: 'error',
				});
			});
	}

	@autobind
	private handleSubmitResetPassword(values: ResetPasswordFormFields): void {
		const { email, token } = this.state;
		this.setState({
			error: undefined,
			status: 'loading',
		});

		this.promisePool
			.add(AuthenticationService.modifyLostPassword(email, token, values.userPass))
			.then(() => {
				this.setState({
					status: 'done',
				});

				Notifications.pushSuccessMessage(
					<>
						Sikeresen beállítottad az új jelszavad, most már <Link to="/bejelentkezes/">ide kattintva</Link>{' '}
						be tudsz jelentkezni
					</>,
				);
			})
			.catch((response: HttpError) => {
				let error: string = 'Hiba történt!';

				switch (response.body.errorCode) {
					case 'WRONG_EMAIL_OR_TOKEN':
						error = 'Érvénytelen vagy lejárt token!';
						break;
					case 'WRONG_EMAIL_OR_PASSWORD':
						error = 'Érvénytelen felhasználó.';
						break;
					case 'INVALID_PASSWORD':
						error =
							'Érvénytelen jelszó (legalább 8 karakter, minimum egy szám és egy betű karaktert kell hogy tartalmazzon)';
						break;
					default:
						break;
				}

				this.setState({
					status: 'error',
				});

				Notifications.pushDangerMessage(error);

				this.promisePool.addTimeout().then(() => {
					this.setState({
						status: undefined,
					});
				});

				logError(response);
			});
	}

	protected renderLeftColumn(): ReactNode {
		return (
			<div className="d-none d-md-block text-center">
				<img src={imgLostPassword} alt="login" width="75%" />
			</div>
		);
	}

	protected renderRightColumn(): ReactNode {
		const { status, page, isKeyValid } = this.state;

		if (page === 'reset-password') {
			return (
				<ResetPasswordForm
					onSubmit={this.handleSubmitResetPassword}
					status={status}
					isKeyValid={isKeyValid}
					initialValues={{ userPass: undefined, userPass1: undefined }}
				/>
			);
		}

		return <LostPasswordForm onSubmit={this.handleSubmitLostPassword} status={status} />;
	}

	public render(): ReactNode {
		if (AuthenticationService.hasMFA()) {
			return <Navigate to="/" />;
		}

		return super.render();
	}
}
