import type { ReportDialogOptions } from '@sentry/react';
import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, Routes, useLocation, useNavigationType } from 'react-router-dom';
import AuthenticationService from '@service/AuthenticationService';
import { SENTRY_DSN, BM_ENVIRONMENT, BM_VERSION } from './Config';

interface SentryUserData {
	email?: string;
	name?: string;
}

export function showReportDialog(options: ReportDialogOptions, userPromise: Promise<SentryUserData>): void {
	userPromise.then((user) => {
		Sentry.showReportDialog({
			title: 'Úgy tűnik valami hiba történt.',
			subtitle: 'A fejlesztőcsapatnak jeleztük a hibát.',
			subtitle2: 'Ha szeretnél segíteni a hiba javításában, kérjük írd le mi történt pontosan.',
			labelName: 'Név',
			labelEmail: 'Email',
			labelComments: 'Mi történt?',
			labelClose: 'Bezárás',
			labelSubmit: 'Beküldés',
			errorGeneric: 'Ismeretlen hiba történt a beküldés közben. Próbáld meg újra.',
			errorFormEntry: 'Néhány mezőt érvénytelenül töltöttél ki. Kérlek javítsd ki őket és utána küld be újra.',
			successMessage: 'A beküldés sikeres. Köszönjük!',
			...options,
			user,
		});
	});
}

Sentry.init({
	dsn: BM_ENVIRONMENT === 'development' ? undefined : SENTRY_DSN,
	release: `customer-portal-webapp@${BM_VERSION}`,
	environment: BM_ENVIRONMENT,
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			),
		}),
		new Sentry.Replay(),
	],
	beforeSend: (event, _hint) => {
		if (event.exception) {
			showReportDialog(
				{
					eventId: event.event_id,
				},
				AuthenticationService.getUserData()
					.then((userData) => ({
						email: userData.email,
						name: `${userData.lastName} ${userData.firstName}`,
					}))
					.catch(() => ({ email: undefined, name: undefined })),
			);
		}

		return event;
	},
	tracesSampleRate: BM_ENVIRONMENT === 'development' ? 0 : 1.0,
	replaysSessionSampleRate: BM_ENVIRONMENT === 'development' ? 0 : 0.1,
	replaysOnErrorSampleRate: BM_ENVIRONMENT === 'development' ? 0 : 1.0,
});

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
