import { Row, Col, getUrlParameter, Spinner } from '@bankmonitor/bm-ui-kit';
import type { ReactNode } from 'react';
import React from 'react';
import type { AbstractPageProps, AbstractPageState } from '@page/AbstractPage';
import { AbstractPage } from '@page/AbstractPage';
import Consultant from '@page/auth/component/Consultant';
import CommonService from '@service/CommonService';
import type { ConsultantProfileData } from '@service/CommonService.type';
import { logError } from '@util/sentry-error';

export interface AbstractAuthPageState extends AbstractPageState {
	consultantId: string;
	consultantLoading: boolean;
	consultantData: ConsultantProfileData;
}

export abstract class AbstractAuthPage<
	Props extends AbstractPageProps = AbstractPageProps,
	State extends AbstractAuthPageState = AbstractAuthPageState,
> extends AbstractPage<Props, State> {
	protected getInitialState(): State {
		let consultantId = getUrlParameter('consultantId', undefined);

		if (consultantId === '') {
			consultantId = undefined;
		}

		return {
			...super.getInitialState(),
			consultantId,
			consultantLoading: !!consultantId,
			consultantData: undefined,
		} as any as State;
	}

	public componentDidMount(): void {
		const { consultantId } = this.state;

		if (consultantId !== undefined) {
			this.promisePool
				.add(CommonService.getConsultantData(consultantId))
				.then((consultantData) => {
					this.setState({ consultantData });
				})
				.catch((error: Error) => {
					logError(error);
				})
				.finally(() => {
					this.setState({
						consultantLoading: false,
					});
				});
		}
	}

	protected abstract renderLeftColumn(): ReactNode;
	protected abstract renderRightColumn(): ReactNode;

	public renderContent(): ReactNode {
		const { consultantData, consultantLoading } = this.state;

		let leftColumn: ReactNode = '';

		if (consultantLoading) {
			leftColumn = (
				<div className="text-center">
					<Spinner size="3x" variant="primary" />
				</div>
			);
		} else if (consultantData) {
			leftColumn = <Consultant {...consultantData} />;
		} else {
			leftColumn = this.renderLeftColumn();
		}

		return (
			<Row className="justify-content-center align-items-stretch">
				<Col xxl={6} xl={7} lg={8} md={7} className="border-end">
					{leftColumn}
				</Col>
				<Col xl={3} lg={4} md={5}>
					{this.renderRightColumn()}
				</Col>
			</Row>
		);
	}
}
