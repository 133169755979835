import type { HttpError } from '@bankmonitor/bm-ui-kit';
import { BmUIKitConfig, Http } from '@bankmonitor/bm-ui-kit';
import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, useHref, useLinkClickHandler, useLocation } from 'react-router-dom';
import AuthenticationService from '@service/AuthenticationService';
import { Analytics } from '@util/Analytics';
import { GA_MEASUREMENT_ID, getBaseUrl } from '@util/Config';
import { ReadableError } from '@util/ReadableError';
import { Root } from './Root';
import type { TenantConfig } from './tenant/tenant.config';

declare global {
	interface Window {
		BM_NEED_RELOAD: boolean;
		TENANT: TenantConfig;
	}
}

// TOKEN
Http.setPostProcess((url: string, _init: RequestInit, cb: () => Promise<any>) =>
	cb()
		.catch((error: HttpError) => {
			if (error.status === 403 && error.message?.toString().includes('CSRF')) {
				return AuthenticationService.init().then(() =>
					cb().catch((error2) => {
						if (error2.status === 403 && error2.message?.toString().includes('CSRF')) {
							throw new ReadableError('Munkamenet lejárt!', {
								error: error2,
								needLog: false,
							});
						}

						throw error2;
					}),
				);
			}

			throw error;
		})
		.then((response) => {
			if ((url.startsWith('/') || url.startsWith(getBaseUrl())) && !url.includes('VERSION')) {
				AuthenticationService.updateSession();
			}

			return response;
		}),
);

// UI kit link element
BmUIKitConfig.setOptions({
	customLinkProvider: (props) => {
		if (window.BM_NEED_RELOAD || !props.href || !props.href.startsWith('/') || props.download) {
			return props;
		}

		const href = useHref(props.href);
		const internalOnClick = useLinkClickHandler(props.href, {
			target: props.target,
		});

		function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
			if (props.onClick) {
				props.onClick(event);
			}

			if (!event.defaultPrevented) {
				internalOnClick(event);
			}
		}

		return {
			href,
			target: props.target,
			onClick: handleClick,
		};
	},
});

if (GA_MEASUREMENT_ID) {
	Analytics.initialize(GA_MEASUREMENT_ID);
}

const AnalyticsProvider = () => {
	const location = useLocation();

	useEffect(() => {
		Analytics.send({
			hitType: 'pageview',
			page: location.pathname + location.search,
		});
	}, [location]);

	return null;
};

async function init() {
	const rootElement = document.getElementById('root');
	const root = createRoot(rootElement);
	root.render(
		<BrowserRouter>
			<AnalyticsProvider />
			<Root />
		</BrowserRouter>,
	);
}
init();
