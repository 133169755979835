import type { ProgressButtonState } from '@bankmonitor/bm-ui-kit';
import {
	Button,
	Display,
	FeatureFlagWrapper,
	Form,
	FormEmail,
	FormPassword,
	FormSubmitButton,
	getUrlParameter,
} from '@bankmonitor/bm-ui-kit';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import React from 'react';
import AuthenticationService from '@service/AuthenticationService';

interface FormFields {
	email: string;
	password: string;
}

interface Props {
	onSubmit(values: FormFields): void;
	loading?: ProgressButtonState;
}

export default class LoginForm extends React.PureComponent<Props> {
	private getInitialValues(): FormFields {
		return {
			email: getUrlParameter('email', undefined),
			password: undefined,
		};
	}

	public render(): ReactNode {
		const { onSubmit, loading } = this.props;

		return (
			<>
				<Display size="4" className="fw-bold mb-3">
					Bejelentkezés
				</Display>
				<Form onSubmit={onSubmit} initialValues={this.getInitialValues()} readOnly={!!loading}>
					{() => (
						<>
							<FormEmail
								placeholder="E-mail cím"
								name="email"
								prepend={<FontAwesomeIcon icon={solid('user')} />}
								autoComplete="username"
								required
							/>
							<FormPassword
								placeholder="Jelszó"
								name="password"
								prepend={<FontAwesomeIcon icon={solid('lock')} />}
								autoComplete="password"
								required
							/>
							<FormSubmitButton
								className="w-100 mb-2"
								state={loading}
								icon={<FontAwesomeIcon icon={solid('sign-in')} />}
							>
								Bejelentkezés
							</FormSubmitButton>
							<Button
								variant="link"
								className="w-100 ms-3"
								icon={<FontAwesomeIcon icon={solid('key')} />}
								href={AuthenticationService.getRedirectWithParamsUrl('/elfelejtett-jelszo/')}
							>
								Elfelejtettem a jelszavam
							</Button>
						</>
					)}
				</Form>
				<FeatureFlagWrapper flag="mock-feature">
					<strong data-testid="mock_feature">This is a mock feature</strong>
				</FeatureFlagWrapper>
			</>
		);
	}
}
