import { Avatar, Button, Display, Rating } from '@bankmonitor/bm-ui-kit';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import React from 'react';
import imgAvatar from '@asset/img/avatar.svg';
import type { ConsultantProfileData } from '@service/CommonService.type';
import { BM_SITE_URL } from '@util/Config';

export default class Consultant extends React.PureComponent<ConsultantProfileData> {
	render(): ReactNode {
		const {
			title = '',
			name,
			allQuestionsAnswered,
			feedbackCount,
			exteriorId,
			email,
			phone,
			infoText,
			avatarUrl,
		} = this.props;

		return (
			<>
				<Display size="4" className="fw-bold mb-4 text-center text-md-start">
					A Te szakmai tanácsadód:
				</Display>
				<div className="d-md-flex d-block mb-4">
					<div className="text-center text-lg-start me-3 mb-3 mb-lg-0">
						<Avatar
							size="lg"
							className="border border-5 rounded-circle avatar-xl"
							alt={name}
							title={name}
							src={avatarUrl || imgAvatar}
						/>
					</div>
					<div>
						<div className="text-center text-md-start mb-3">
							<Display size="1" bold className=" text-secondary mb-0">
								{name}
							</Display>
							{title}
							<div className="fs-6 mt-3 mt-md-0">
								<strong>
									Értékelés: <Rating rate={allQuestionsAnswered} />{' '}
									{` ${Math.round(allQuestionsAnswered * 10) / 10}`}/5
								</strong>{' '}
								- <small>{feedbackCount} visszajelzés</small>
							</div>
						</div>
						{exteriorId && (
							<Button
								href={`${BM_SITE_URL}/szakertok/${exteriorId}`}
								target="_blank"
								outlined
								variant="secondary"
								size="sm"
								className="me-2 mb-2"
								icon={<FontAwesomeIcon icon={solid('envelope')} />}
							>
								üzenet küldése
							</Button>
						)}
						{exteriorId && (
							<Button
								href={`${BM_SITE_URL}/szakertok/${exteriorId}`}
								target="_blank"
								outlined
								variant="secondary"
								size="sm"
								className="me-2 mb-2"
								icon={<FontAwesomeIcon icon={solid('user-check')} />}
							>
								adatlap
							</Button>
						)}

						<hr className="mt-2 mb-0" />
						{email && (
							<>
								<a href={`mailto:${email}`} className="text-secondary small">
									<FontAwesomeIcon icon={solid('envelope')} className="me-2" />
									{email}
								</a>
								<br />
							</>
						)}
						{phone && (
							<a href={`tel:${phone}`} className="text-secondary small">
								<FontAwesomeIcon icon={solid('phone')} className="me-2" />
								{phone}
							</a>
						)}
					</div>
				</div>
				{infoText && (
					<div className="d-none d-md-block">
						<h3 className="text-secondary">Bemutatkozás</h3>
						<p className="text-break">{infoText}</p>
					</div>
				)}
			</>
		);
	}
}
