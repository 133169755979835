import type { ProgressButtonState } from '@bankmonitor/bm-ui-kit';
import {
	FormSubmitButton,
	Display,
	Button,
	Form,
	FormEmail,
	FormPassword,
	FormTextInput,
} from '@bankmonitor/bm-ui-kit';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autobind from 'autobind-decorator';
import type { ReactNode } from 'react';
import React from 'react';
import AuthenticationService from '@service/AuthenticationService';
import { BM_SITE_URL } from '@util/Config';

export interface FormFields {
	lastName: string;
	firstName: string;
	userEmail: string;
	userPass: string;
	userPass1: string;
}

interface Props {
	onSubmit(values: FormFields): void;
	initialValues: FormFields;
	registerButtonStatus: ProgressButtonState;
}

export default class RegistrationForm extends React.PureComponent<Props> {
	@autobind
	private validSecondPassword(password: string, values: FormFields): string {
		if (values.userPass && password && values.userPass !== password) {
			return 'A két jelszó nem egyezik!';
		}
	}

	private validPassword(password: string): string {
		if (password.length < 8) {
			return 'A jelszónak legalább 8 karakter hosszúnak kell lennie!';
		}

		if (password.length > 64) {
			return 'A jelszó nem lehet hosszabb 64 karakternél!';
		}

		if (!/[a-zA-Z]/.test(password)) {
			return 'A jelszónak tartalmaznia kell legalább egy betűt!';
		}

		if (!/\d/.test(password)) {
			return 'A jelszónak tartalmaznia kell legalább egy számot!';
		}
	}

	public render(): ReactNode {
		const { onSubmit, initialValues, registerButtonStatus } = this.props;

		return (
			<Form onSubmit={onSubmit} initialValues={initialValues}>
				{() => (
					<>
						<Display size="4" className="fw-bold mb-3">
							Regisztrációs adatok
						</Display>
						<FormTextInput
							name="lastName"
							prepend={<FontAwesomeIcon icon={solid('user')} />}
							placeholder="Vezetéknév"
							disabled
							required
						/>
						<FormTextInput
							name="firstName"
							prepend={<FontAwesomeIcon icon={solid('user')} />}
							placeholder="Keresztnév"
							disabled
							required
						/>
						<FormEmail name="userEmail" placeholder="Email cím" autoComplete="email" disabled required />
						<FormPassword
							name="userPass"
							placeholder="Jelszó"
							autoComplete="new-password"
							validate={this.validPassword}
							required
						/>
						<FormPassword
							name="userPass1"
							placeholder="Jelszó mégegyszer"
							autoComplete="new-password"
							validate={this.validSecondPassword}
							required
						/>

						<p className="fs-5 text-muted text-center">
							A lenti gomb megnyomásával elfogadom az{' '}
							<a href={`${BM_SITE_URL}/adatvedelem/`} target="_blank" rel="noreferrer">
								adatvédelmi tájékoztatóban
							</a>{' '}
							foglaltakat.
						</p>
						<FormSubmitButton
							icon={<FontAwesomeIcon icon={solid('right-to-bracket')} />}
							className="w-100"
							state={registerButtonStatus}
						>
							Regisztráció
						</FormSubmitButton>
						<hr />
						<p className="fs-5 text-muted text-center">
							Amennyiben korábban már használtad szolgáltatásunkat, alább tudsz belépni.
						</p>
						<Button
							icon={<FontAwesomeIcon icon={solid('right-to-bracket')} />}
							type="button"
							href={AuthenticationService.getRedirectWithParamsUrl('/')}
							variant="outline-primary"
							className="w-100"
						>
							Belépés
						</Button>
					</>
				)}
			</Form>
		);
	}
}
