import { Container } from '@bankmonitor/bm-ui-kit';
import type { ReactNode } from 'react';
import React from 'react';
import './MaintenancePage.scss';

class MaintenancePage extends React.PureComponent {
	public componentDidMount(): void {
		setTimeout(() => {
			window.location.reload();
		}, 300000);
	}

	public render(): ReactNode {
		const tenant = window.TENANT;

		return (
			<div className="d-flex align-items-center full-height">
				<Container fluid className="text-center maintenance-page">
					<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24.738791 25.400001">
						<defs>
							<clipPath id="a" clipPathUnits="userSpaceOnUse">
								<path d="m0 595h842v-595h-842z" />
							</clipPath>
						</defs>
						<g transform="translate(-85.5 -129)">
							<g transform="matrix(.353 0 0 -.353 -167 327)">
								<g transform="translate(694 42.9)">
									<g clipPath="url(#a)" transform="matrix(.606 0 0 .606 8.39 176)" fill="#555d66">
										<g transform="translate(77.9 554)">
											<path
												d="m0 0c-26.7 0-48.5-22.3-48.5-49.8s21.8-49.8 48.5-49.8 48.5 22.3 48.5 49.8-21.8 49.8-48.5 49.8m0-108c-31.2 0-56.6 26.1-56.6 58.2s25.4 58.2 56.6 58.2 56.6-26.1 56.6-58.2-25.4-58.2-56.6-58.2"
												fill="#555d66"
											/>
										</g>
										<g transform="translate(99.1 541)">
											<path
												d="m0 0-11.6-6.68 2.84-1.69-36.9-63.2 5.58-3.43 36.9 63.3 2.84-1.69z"
												fill="#555d66"
											/>
										</g>
										<g transform="translate(94.3 478)">
											<path
												d="m0 0c-2.62 0-4.75 4.64-4.75 10.4 0 5.73 2.13 10.4 4.75 10.4s4.75-4.65 4.75-10.4c0-5.76-2.13-10.4-4.75-10.4m0 27.3c-7.8 0-14.1-7.58-14.1-16.9 0-9.35 6.32-16.9 14.1-16.9 7.8 0 14.1 7.58 14.1 16.9 0 9.35-6.32 16.9-14.1 16.9"
												fill="#555d66"
											/>
										</g>
										<g transform="translate(56.9 505)">
											<path
												d="m0 0c-2.62 0-4.75 4.64-4.75 10.4 0 5.73 2.13 10.4 4.75 10.4s4.75-4.65 4.75-10.4c0-5.76-2.13-10.4-4.75-10.4m0.001 27.3c-7.8 0-14.1-7.58-14.1-16.9 0-9.35 6.32-16.9 14.1-16.9 7.8 0 14.1 7.58 14.1 16.9 0 9.35-6.32 16.9-14.1 16.9"
												fill="#555d66"
											/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</svg>
					<br />
					<br />
					<span className="h1">Karbantartás miatt az oldal átmenetileg nem elérhető!</span>
					<footer>
						by <a href={`https:${tenant?.homePage}`}>{tenant?.homePage}</a>
					</footer>
				</Container>
			</div>
		);
	}
}

export default MaintenancePage;
