import React, { useEffect, useState } from 'react';
import App from './App';
import { initTenant } from './tenant/tenantService';

export const Root = () => {
	const [isTenantInitialized, setTenantInitialized] = useState(false);
	useEffect(() => {
		(async () => {
			await initTenant();
			setTenantInitialized(true);
		})();
	}, []);

	return isTenantInitialized ? <App /> : null;
};
