import React from 'react';
import { Navigate } from 'react-router-dom';

export default [
	{
		path: '/',
		element: React.lazy(() => import('@page/LeadListPage')),
	},
	{
		path: '/ugyleteim',
		element: () => <Navigate to="/" />,
	},
	{
		path: '/ugyleteim/:id',
		element: React.lazy(() => import('@page/LeadPage')),
	},
	{
		path: '/ugyleteim/:id/:bankCaseId',
		element: React.lazy(() => import('@page/LeadPage')),
	},
	{
		path: '/bejelentkezes/',
		element: () => <Navigate to="/" />,
	},
	{
		path: '/regisztracio/',
		element: React.lazy(() => import('@page/AlreadyLoggedInPage')),
	},
	{
		path: '/elfelejtett-jelszo/',
		element: React.lazy(() => import('@page/AlreadyLoggedInPage')),
	},
];
