import { Container, Display } from '@bankmonitor/bm-ui-kit';
import type { ReactNode } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import imgLost from '@asset/img/page-not-found.svg';

export default class NotFoundPage extends React.PureComponent {
	public render(): ReactNode {
		return (
			<div className="d-flex align-items-center min-vh-100">
				<Container fluid className="text-center">
					<img className="img-fluid" src={imgLost} alt="Lost" style={{ maxWidth: '360px' }} />
					<div className="text-center my-4">
						<Display size="4">A keresett oldal nem található 😭</Display>
						<p className="text-muted mb-4">Úgy tűnik eltévedtél?</p>
						<Link to="/">Ugorj vissza a kezdőoldalra</Link>
					</div>
				</Container>
			</div>
		);
	}
}
