/* eslint-disable no-console */
import { Cookie } from '@bankmonitor/bm-ui-kit';
import { BM_ENVIRONMENT } from './Config';

const logEnabled = BM_ENVIRONMENT !== 'production' || Cookie.get('bm-debug', 'false') === 'true';

export class Console {
	public static log(message?: any, ...args: any[]): void {
		if (logEnabled) {
			console.log(message, ...args);
		}
	}

	public static info(message?: any, ...args: any[]): void {
		if (logEnabled) {
			console.info(message, ...args);
		}
	}

	public static error(message?: any, ...args: any[]): void {
		console.error(message, ...args);
	}

	public static warn(message?: any, ...args: any[]): void {
		if (logEnabled) {
			console.warn(message, ...args);
		}
	}

	public static time(label?: string): void {
		if (logEnabled) {
			console.time(label);
		}
	}

	public static timeEnd(label?: string): void {
		if (logEnabled) {
			console.timeEnd(label);
		}
	}
}
