import type { HttpError, CustomError } from '@bankmonitor/bm-ui-kit';
import { captureException } from '@sentry/react';
import { Console } from './console';

export function logError(error: Error | CustomError): void {
	Console.error(error);

	if (
		(error.name === 'HttpError' && [0, 401, 403, 404, 410].includes((error as HttpError).status)) ||
		('needLog' in error && error.needLog === false)
	) {
		return;
	}

	captureException(error, (scope) => {
		if ('getExtras' in error) {
			scope.setExtras(error.getExtras());
		}

		return scope;
	});
}
