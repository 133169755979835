import type { ProgressButtonState } from '@bankmonitor/bm-ui-kit';
import { Spinner, Display, Button, Alert, getUrlParameter } from '@bankmonitor/bm-ui-kit';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autobind from 'autobind-decorator';
import type { ReactNode } from 'react';
import React from 'react';
import { Navigate } from 'react-router-dom';
import imgRegistration from '@asset/img/registration.svg';
import Notifications from '@component/Notifications';
import RegistrationForm from '@page/auth/component/RegistrationForm';
import type { FormFields } from '@page/auth/component/RegistrationForm';
import AuthenticationService from '@service/AuthenticationService';
import type { AbstractPageProps } from '../AbstractPage';
import { AbstractAuthPage } from './AbstractAuthPage';
import type { AbstractAuthPageState } from './AbstractAuthPage';

interface State extends AbstractAuthPageState {
	registered: boolean;
	isKeyValid: boolean;
	initialValues: FormFields;
	status: ProgressButtonState;
}

export default class RegistrationPage extends AbstractAuthPage<AbstractPageProps, State> {
	protected readonly pageTitle = 'Ügyfélportál regisztráció';
	protected readonly pageLead = 'Gyorsítsd fel az ügyintézés folyamatát!';
	private emailToken = getUrlParameter('token');

	protected getInitialState(): State {
		return {
			...super.getInitialState(),
			registered: false,
			isKeyValid: undefined,
			status: undefined,
			initialValues: {
				lastName: '',
				firstName: '',
				userEmail: '',
				userPass: '',
				userPass1: '',
			},
		};
	}

	public componentDidMount(): void {
		this.emailToken = getUrlParameter('token');
		super.componentDidMount();

		this.validateToken();
	}

	private validateToken(): void {
		const email = getUrlParameter('email');

		AuthenticationService.validateEmailToken(email, this.emailToken)
			.then((response) => {
				this.setState({
					isKeyValid: true,
					initialValues: {
						lastName: response.lastName,
						firstName: response.firstName,
						userEmail: email,
						userPass: '',
						userPass1: '',
					},
				});
			})
			.catch((response: Error) => {
				const { message = 'Váratlan hiba történt!' } = response;

				Notifications.pushDangerMessage(message);

				this.setState({
					status: 'error',
					isKeyValid: false,
				});
			});
	}

	@autobind
	private handleSubmit(values: FormFields): void {
		this.setState({
			status: 'loading',
			initialValues: values,
		});
		const { userEmail, userPass } = values;

		AuthenticationService.registration(userEmail, userPass, this.emailToken)
			.then(() => {
				this.setState({
					registered: true,
					status: 'done',
				});
			})
			.catch((response: Error) => {
				const { message = 'Váratlan hiba történt!' } = response;

				Notifications.pushDangerMessage(message);

				this.setState({
					status: 'error',
				});

				this.promisePool.addTimeout().then(() => {
					this.setState({
						status: undefined,
					});
				});
			});
	}

	protected renderLeftColumn(): ReactNode {
		return (
			<div className="d-none d-md-block text-center">
				<img src={imgRegistration} alt="regisztráció" width="75%" />
			</div>
		);
	}

	protected renderRightColumn(): ReactNode {
		const { registered, initialValues, isKeyValid, status } = this.state;

		if (registered) {
			return (
				<>
					<Display size="4" className="fw-bold mb-3">
						Sikeres regisztráció!
					</Display>
					<Alert icon="auto" variant="success">
						Kérjük lépjen be!
					</Alert>
					<Button
						icon={<FontAwesomeIcon icon={solid('right-to-bracket')} />}
						type="button"
						href="/"
						variant="outline-primary"
						className="w-100"
					>
						Belépés
					</Button>
				</>
			);
		}

		if (isKeyValid === undefined) {
			return (
				<div className="text-center py-5">
					<Spinner size="2x" variant="primary" />
					<div className="mt-3">Ellenőrzés...</div>
				</div>
			);
		}

		if (isKeyValid === false) {
			return (
				<>
					<Alert icon="auto" variant="danger">
						<Alert.Heading>Hibás link!</Alert.Heading>
						<p>Érvénytelen regisztrációs link, kérlek vedd fel a kapcsolatot az ügyintéződdel!</p>
					</Alert>
					<Button
						icon={<FontAwesomeIcon icon={solid('right-to-bracket')} />}
						type="button"
						href="/"
						variant="outline-primary"
						className="w-100"
					>
						Belépés
					</Button>
				</>
			);
		}

		return (
			<RegistrationForm
				onSubmit={this.handleSubmit}
				initialValues={initialValues}
				registerButtonStatus={status}
			/>
		);
	}

	public render(): ReactNode {
		if (AuthenticationService.hasMFA()) {
			return <Navigate to="/" />;
		}

		return super.render();
	}
}
