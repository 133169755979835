import type { ProgressButtonState } from '@bankmonitor/bm-ui-kit';
import autobind from 'autobind-decorator';
import type { ReactNode } from 'react';
import React from 'react';
import { Navigate } from 'react-router-dom';
import imgLogin from '@asset/img/login.svg';
import imgMfa from '@asset/img/mfa.svg';
import Notifications from '@component/Notifications';
import LoginForm from '@page/auth/component/LoginForm';
import MFAComponent from '@page/auth/component/MFAComponent';
import AuthContext from '@service/AuthenticationContext';
import AuthenticationService from '@service/AuthenticationService';
import type { UserData } from '@service/AuthenticationService.type';
import type { ConsultantProfileData } from '@service/CommonService.type';
import type { AbstractPageProps } from '../AbstractPage';
import type { AbstractAuthPageState } from './AbstractAuthPage';
import { AbstractAuthPage } from './AbstractAuthPage';

interface State extends AbstractAuthPageState {
	loading: ProgressButtonState;
	initialValues: FormFields;
	showMFAComponent: boolean;
	consultantData: ConsultantProfileData;
}

interface FormFields {
	email: string;
	password: string;
}

export default class LoginPage extends AbstractAuthPage<AbstractPageProps, State> {
	protected readonly pageTitle = 'Ügyfélportál bejelentkezés';
	protected readonly pageLead = 'Gyorsítsd fel az ügyintézés folyamatát!';

	declare context: React.ContextType<typeof AuthContext>;
	public static contextType = AuthContext;

	protected getInitialState(): State {
		return {
			...super.getInitialState(),
			loading: undefined,
			initialValues: {
				email: '',
				password: '',
			},
			showMFAComponent: false,
		};
	}

	public componentDidMount(): void {
		super.componentDidMount();

		this.checkMFA();
	}

	@autobind
	private checkMFA(): void {
		const { userData } = this.context;

		this.setState({
			showMFAComponent: userData && AuthenticationService.hasMFA(),
		});
	}

	@autobind
	private handleSubmit(values: FormFields): void {
		const { setMFAStatus, setUser } = this.context;

		setMFAStatus('none');
		AuthenticationService.resetMFA();

		this.setState({
			loading: 'loading',
			initialValues: values,
		});

		this.promisePool
			.add(AuthenticationService.login(values.email, values.password))
			.then(() => {
				setTimeout(() => {
					this.promisePool.add(AuthenticationService.getUserData()).then((userData: UserData) => {
						this.setState({
							loading: 'done',
							showMFAComponent: true,
						});

						setUser(userData);
					});
				}, 500);
			})
			.catch((error: Error) => {
				Notifications.showErrorMessage(error);

				this.setState({
					loading: 'error',
				});
			})
			.finally(() => {
				this.promisePool.addTimeout().then(() =>
					this.setState({
						loading: undefined,
					}),
				);
			});
	}

	protected renderLeftColumn(): ReactNode {
		const { showMFAComponent } = this.state;

		if (showMFAComponent) {
			return (
				<div className="d-none d-md-block text-center">
					<img src={imgMfa} alt="mfa" width="75%" />
				</div>
			);
		}

		return (
			<div className="d-none d-md-block text-center">
				<img src={imgLogin} alt="login" width="75%" />
			</div>
		);
	}

	protected renderRightColumn(): ReactNode {
		const { loading, showMFAComponent } = this.state;

		const { userData } = this.context;

		if (showMFAComponent) {
			return <MFAComponent phone={userData?.phone} />;
		}

		return <LoginForm onSubmit={this.handleSubmit} loading={loading} />;
	}

	public render(): ReactNode {
		if (AuthenticationService.hasMFA()) {
			return <Navigate to="/" />;
		}

		return super.render();
	}
}
