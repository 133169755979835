import { Container, Display, LandingContainer, PromisePool } from '@bankmonitor/bm-ui-kit';
import type { ReactNode } from 'react';
import React from 'react';
import Header from '@component/Header';
import Notifications from '@component/Notifications';

export interface AbstractPageProps {}

export interface AbstractPageState {}

export abstract class AbstractPage<
	Props extends AbstractPageProps = AbstractPageProps,
	State extends AbstractPageState = AbstractPageState,
> extends React.Component<Props, State> {
	protected readonly promisePool = new PromisePool();

	public constructor(props: Props) {
		super(props);

		this.state = this.getInitialState();
	}

	protected getInitialState(): State {
		return {} as any as State;
	}

	public componentWillUnmount(): void {
		this.promisePool.cancel();
	}

	protected abstract readonly pageTitle: string;
	protected abstract readonly pageLead: string;

	protected renderHeader(): ReactNode {
		return (
			<>
				<Header />
				<LandingContainer pattern="none" variant="secondary">
					<Display bold size="1" className="d-none d-md-block">
						{this.pageTitle}
					</Display>
					<Display bold size="5" className="d-block d-md-none">
						{this.pageTitle}
					</Display>
					<Display className="mt-1" size="6">
						{this.pageLead}
					</Display>
				</LandingContainer>
			</>
		);
	}

	protected abstract renderContent(): ReactNode;

	public render(): ReactNode {
		return (
			<>
				{this.renderHeader()}
				<Container className="mt-4 mt-md-5 pb-5">{this.renderContent()}</Container>
				<Notifications />
			</>
		);
	}
}
