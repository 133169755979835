import type { ProgressButtonState } from '@bankmonitor/bm-ui-kit';
import { FormSubmitButton, Display, FormEmail, Button, Form } from '@bankmonitor/bm-ui-kit';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import React from 'react';
import AuthenticationService from '@service/AuthenticationService';

export interface FormFields {
	email: string;
}
interface Props {
	onSubmit(values: FormFields): void;
	status: ProgressButtonState;
}

export default class LostPasswordForm extends React.PureComponent<Props> {
	public render(): ReactNode {
		const { onSubmit, status } = this.props;

		return (
			<>
				<Display size="4" className="fw-bold mb-3">
					Elfelejtett jelszó
				</Display>
				<p>
					Amennyiben{' '}
					<strong>
						<FontAwesomeIcon icon={solid('circle-question')} /> elfelejtetted jelszavad
					</strong>
					, kérlek add meg a regisztráció során használt{' '}
					<strong>
						<FontAwesomeIcon icon={solid('envelope')} /> Email címed
					</strong>{' '}
					és segítünk új jelszót beállítani.
				</p>
				<Form onSubmit={onSubmit} readOnly={!!status}>
					{() => (
						<>
							<FormEmail name="email" placeholder="Email cím" autoComplete="username" required />
							<FormSubmitButton
								className="w-100 mb-3"
								icon={<FontAwesomeIcon icon={solid('paper-plane')} />}
								state={status}
							>
								Új jelszó kérése
							</FormSubmitButton>
							<Button
								variant="link"
								className="w-100 ms-3"
								href={AuthenticationService.getRedirectWithParamsUrl('/')}
								icon={<FontAwesomeIcon icon={solid('sign-in')} />}
							>
								Bejelentkezés
							</Button>
						</>
					)}
				</Form>
			</>
		);
	}
}
