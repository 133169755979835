import type { ProgressButtonState } from '@bankmonitor/bm-ui-kit';
import { FormSubmitButton, Display, Spinner, FormPassword, Alert, Button, Form } from '@bankmonitor/bm-ui-kit';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import autobind from 'autobind-decorator';
import type { ReactNode } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import AuthenticationService from '@service/AuthenticationService';

export interface FormFields {
	userPass: string;
	userPass1: string;
}
interface Props {
	onSubmit(values: FormFields): void;
	status: ProgressButtonState;
	isKeyValid: boolean;
	initialValues: FormFields;
}

export default class ResetPasswordForm extends React.PureComponent<Props> {
	@autobind
	private validSecondPassword(password: string, values: FormFields): string {
		if (values.userPass !== password) {
			return 'A két jelszó nem egyezik!';
		}

		return undefined;
	}

	private renderErrors(): ReactNode {
		const { isKeyValid } = this.props;

		if (isKeyValid === undefined) {
			return (
				<Alert icon="auto" variant="warning">
					Feldolgozás...
				</Alert>
			);
		}

		return null;
	}

	public render(): ReactNode {
		const { onSubmit, initialValues, status, isKeyValid } = this.props;

		if (isKeyValid === undefined) {
			return (
				<div className="text-center py-5">
					<Spinner size="2x" variant="primary" />
					<div className="mt-3">Ellenőrzés...</div>
				</div>
			);
		}

		if (!isKeyValid) {
			return (
				<Alert variant="danger" icon="auto">
					<Alert.Heading>Érvénytelen link!</Alert.Heading>
					<p>
						Próbálj meg egy új elfelejtett jelszó kiküldést az{' '}
						<Link to={AuthenticationService.getRedirectWithParamsUrl('/elfelejtett-jelszo/')}>
							alább linkre kattintva
						</Link>
						.
					</p>
				</Alert>
			);
		}

		return (
			<>
				<Display size="4" className="fw-bold mb-3">
					Új jelszó
				</Display>
				<Form onSubmit={onSubmit} initialValues={initialValues} readOnly={!!status}>
					{() => (
						<>
							<p>Add meg az új jelszavad</p>
							{this.renderErrors()}
							<FormPassword name="userPass" placeholder="Jelszó" autoComplete="new-password" required />
							<FormPassword
								name="userPass1"
								placeholder="Jelszó mégegyszer"
								autoComplete="new-password"
								validate={this.validSecondPassword}
								required
							/>
							<FormSubmitButton
								className="w-100 mb-3"
								icon={<FontAwesomeIcon icon={solid('right-to-bracket')} />}
								state={status}
							>
								Új jelszó beállítása
							</FormSubmitButton>
							<Button
								variant="link"
								className="w-100 ms-3"
								href={AuthenticationService.getRedirectWithParamsUrl('/')}
							>
								Bejelentkezés
							</Button>
						</>
					)}
				</Form>
			</>
		);
	}
}
