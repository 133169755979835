export enum Tenant {
	BM = 'BM',
	OVB = 'OVB',
	DH = 'DH',
	PLDPZGY = 'PLDPZGY',
}

type Img = {
	src: string;
	alt: string;
	height?: number;
	width?: number;
};
export type TenantConfig = {
	name: string;
	code: Tenant;
	homePage: string;
	defaultImg: Img;
	secondaryImg: Img;
	miniLogo?: Img;
	contact: {
		email: string;
		phone?: string;
	};
};
export const TENANT_CONFIG: Record<Tenant, TenantConfig> = {
	BM: {
		name: 'Bankmonitor',
		code: Tenant.BM,
		homePage: 'bankmonitor.hu',
		defaultImg: {
			alt: 'bankmonitor',
			src: '/img/bm/bankmonitor.svg',
		},
		secondaryImg: {
			alt: 'bankmonitor',
			src: '/img/bm/bankmonitor-white.svg',
		},
		miniLogo: {
			alt: 'Bankmonitor logo',
			src: '/img/bm/bankmonitor-mini.svg',
		},
		contact: {
			email: 'ugyfelportal@bankmonitor.hu',
		},
	},
	OVB: {
		name: 'OVB',
		code: Tenant.OVB,
		homePage: 'ovb.hu',
		defaultImg: {
			alt: 'ovb',
			src: '/img/ovb/logo.svg',
			height: 40,
			width: 60,
		},
		secondaryImg: {
			alt: 'ovb',
			src: '/img/ovb/logo.svg',
			height: 40,
			width: 60,
		},
		contact: {
			email: 'ugyfelportal@ovb.hu',
		},
	},
	DH: {
		name: 'DH',
		code: Tenant.DH,
		homePage: 'dh.hu',
		defaultImg: {
			alt: 'dh',
			src: '/img/dh/pdf-logo-mini.svg',
			height: 40,
			width: 60,
		},
		secondaryImg: {
			alt: 'dh',
			src: '/img/dh/pdf-logo-mini.svg',
			height: 40,
			width: 60,
		},
		contact: {
			email: 'ugyfelportal@dh.hu',
		},
	},
	PLDPZGY: {
		name: 'Példa pénzügy',
		code: Tenant.PLDPZGY,
		homePage: 'pld.hu',
		defaultImg: {
			alt: 'pelda-penzugy',
			src: '/img/pldpzgy/pldpzgy-logo.svg',
			height: 40,
			width: 60,
		},
		secondaryImg: {
			alt: 'pelda-penzugy',
			src: '/img/pldpzgy/pldpzgy-logo.svg',
			height: 40,
			width: 60,
		},
		contact: {
			email: 'ugyfelportal@pldpzgy.hu',
		},
	},
};
